import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import moment from 'moment'
import { parseDate } from 'react-day-picker/moment'
import { Col, Modal, Row } from 'react-bootstrap'
import {
  Button,
  Card,
  CustomDate,
  FABV2,
  FormText,
  Geocoding,
  Icon,
  Select,
  Switch,
  TableV2,
  Title,
  ClientContacts,
  Loading,
  SwitchV2,
} from 'src/components'

import {
  faBook,
  faCheckDouble,
  faEdit,
  faEnvelope,
  faSave,
} from '@fortawesome/free-solid-svg-icons'
import { history } from 'src/App'
import { useLocation } from 'react-router-dom'

import {
  getPaymentMethods,
  onSetModalOrder,
  setModalCreateClient,
  setModalEditClient,
} from 'src/actions/utilities.actions'
import { selectMethodPayment, trim } from 'src/selectors/utilities.selector'

import { getProviderByNit } from 'src/actions/producer.actions'
import { getPhaseTypes } from 'src/actions/resolutions.actions'

import {
  actionTypes as clientActions,
  getClientContacts,
  onCreateClientStep,
} from 'src/actions/clients.actions'
import { simpleClientsV2 } from 'src/selectors/clients.selector'

import { getWarehouseLite } from 'src/actions/warehouse.actions'
import {
  actionTypes as typeC,
  getCommissionOnQuoteField,
  getCompanyField,
  getTaxPressBellQuoteField,
  getWithoutTaxOnQuoteField,
} from 'src/actions/company.actions'
import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectCompanyFieldProductRepeat,
  selectCompanyFieldValue,
  selectIdentificationTypes,
} from 'src/selectors/company.selector'

import { selectCurrentModule } from 'src/selectors/user.selector'

import { getCurrentUserRolesAction } from 'src/actions/modules.actions'
import { isAllowed } from 'src/selectors/modules.selector'

import {
  actionTypes as typesO,
  actionTypes,
  createNewOrder,
  getOrderBalanceByCustomers,
  getSingleOrder,
  onConfirmOrderVisit,
  updateOrderProducts,
} from 'src/actions/orders.actions'

import {
  selectGetOrdersWithBalance,
  selectGetSingleOrder,
} from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerInfo,
  handlerSuccess,
  hasErrors,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'
import { getNumber, toMoney, validateGeoLocation } from 'src/utils/utilities'

import CreateSubscription from '../../Subscription/CreateSubscription'
import { itemTypes } from 'src/components/Items/ItemsToSelect'
import { SelectClient } from 'src/components'
import { geolocated } from 'react-geolocated'
import { ItemSelectionTable } from 'src/components/Transaction/ItemSelectionTable'
import { useSession } from 'src/hooks/useSession'
import felDocumentTypes, { felDocumentType } from 'src/enums/felDocumentTypes'
import { orderDispatchType } from 'src/enums/orderDispatchStatus'
import { Country } from 'src/enums/countries'
import IdentificationField from 'src/components/IdentificationField/IdentificationField'
import { identificationTypesEnum } from 'src/enums/identificationTypes'

import companyFields, {
  companyFieldsInvoice,
  companyFieldsSell,
} from 'src/enums/companyFields'

import { PaymentType } from 'src/enums/paymentTypes'
import { clientPermissions, orderPermissions } from 'src/enums/permissions'
import { MultiplePayments } from 'src/components/modal/Orders/MultiplePayments'
import { BankDetails } from 'src/content/Orders/OrderCreate/BankDetails'
import { getAllCategorizations } from 'src/actions/categorization.actions'
import { categoryType } from 'src/components/folders/Folder'
import SelectFiscalDocumentSV from 'src/components/custom/SelectFiscalDocumentSV/SelectFiscalDocumentSV'
import { selectIncoterm } from 'src/selectors/currencies.selector'
import { getIncoterm } from 'src/actions/currencies.actions'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import ClientTaxExempt from 'src/components/Clients/ClientTaxExempt/ClientTaxExempt'
import { showAlert } from 'src/actions/alert.actions'

import {
  formatFELConfiguration,
  getFELConfigurationByTaxExemptValues,
} from 'src/utils/orderHelpers/OrderFunctions'
import useTaxBehavior from 'src/hooks/useTaxBehavior'
import OrderItemsPriceWithError from 'src/content/Orders/OrderCreate/OrderItemsPriceWithError'

const defaultClient = {
  value: 0,
  id: 0,
  label: 'Consumidor Final',
  nit: 'CF',
  new: true,
  save: false,
  code: '3000CA',
}

const CreateOrder = props => {
  const { isGeolocationAvailable, isGeolocationEnabled, coords } = props

  const [changeCash, setChangeCash] = useState(0)

  // visit permissions
  const canConfirmVisitCompleteInventory = useSelector(state => isAllowed(state, [1609]))
  const canConfirmVisitParcialInventory = useSelector(state => isAllowed(state, [1610]))

  const dispatch = useDispatch()
  const module = useSelector(selectCurrentModule)
  const balance = useSelector(selectGetOrdersWithBalance)
  const clients = useSelector(simpleClientsV2)
  const country = useSelector(selectCompanyCountry)
  const identificationTypes = useSelector(selectIdentificationTypes)
  const incoterm = useSelector(selectIncoterm)
  const allowBillPayments = useSelector(state => selectCompanyFieldById(state, 119))
  const felData = useSelector(state => selectCompanyFieldById(state, 90))

  const felActive = useSelector(state =>
    selectCompanyFieldById(state, companyFieldsInvoice.automaticFEL),
  )
  const useExportationInvoice = useSelector(state =>
    selectCompanyFieldById(state, companyFields.useExportationInvoice),
  )
  const fieldRepeat = useSelector(selectCompanyFieldProductRepeat)
  const useDrafts =
    useSelector(state => selectCompanyFieldById(state, companyFieldsSell.useDraftSale))
      ?.value === 'true'

  const loadingClients = useSelector(state =>
    loadingSelector([clientActions.GET_CLIENT_POLYGON])(state),
  )

  const paymentMethodPermissions = useSelector(state => [
    {
      id: PaymentType.CASH,
      enabled: isAllowed(state, [orderPermissions.createOrderCash]),
    },
    {
      id: PaymentType.CREDIT,
      enabled: isAllowed(state, [orderPermissions.createOrderCredit]),
    },
    {
      id: PaymentType.CARD,
      enabled: isAllowed(state, [orderPermissions.createOrderCard]),
    },
    {
      id: PaymentType.BANK_TRANSFER,
      enabled: isAllowed(state, [orderPermissions.createOrderTransfer]),
    },
    {
      id: PaymentType.MULTIPLE,
      enabled: isAllowed(state, [orderPermissions.createOrderMultiple]),
    },
  ])
  const paymentMethods = useSelector(selectMethodPayment).filter(
    payment =>
      (payment.link && paymentMethodPermissions.every(p => p.id !== payment.id)) ||
      paymentMethodPermissions.find(p => p.id === payment.id)?.enabled,
  )
  const cardSurcharge = useSelector(state =>
    selectCompanyFieldValue(state, companyFields.cardSurcharge),
  )
  const automaticCardSurcharge = useSelector(state =>
    selectCompanyFieldValue(state, companyFields.automaticCardSurcharge),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([actionTypes.CREATE_NEW_ORDER, actionTypes.ON_CONFIRM_VISIT_ORDER])(
      state,
    ),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrors([actionTypes.CREATE_NEW_ORDER, actionTypes.ON_CONFIRM_VISIT_ORDER])(state),
  )
  const errorCreate = useSelector(state =>
    singleErrorSelector([
      actionTypes.CREATE_NEW_ORDER,
      actionTypes.ON_CONFIRM_VISIT_ORDER,
    ])(state),
  )

  const loadingUpdate = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_PRODUCTS_ORDER])(state),
  )
  const hasErrorUpdate = useSelector(state =>
    hasErrors([actionTypes.UPDATE_PRODUCTS_ORDER])(state),
  )
  const errorUpdate = useSelector(state =>
    singleErrorSelector([actionTypes.UPDATE_PRODUCTS_ORDER])(state),
  )

  const singleOrder = useSelector(selectGetSingleOrder)
  const loadingSingleOrder = useSelector(state =>
    loadingSelector([actionTypes.GET_SINGLE_ORDER])(state),
  )

  const loadingOB = useSelector(state =>
    loadingSelector([typesO.GET_ORDER_BALANCE])(state),
  )

  const canViewAll = useSelector(state => isAllowed(state, [clientPermissions.viewAll]))
  const seeBalances = useSelector(state => isAllowed(state, [1135]))
  // Visita
  const canChooseFEL = useSelector(state => isAllowed(state, [1607]))
  const [order, setOrder] = useState({
    client: {},
    enabledAt: new Date(),
    advance: 0,
    payment: paymentMethods[0],
    felDocumentType: felDocumentTypes[0],
    taxExemptValues: {},
  })
  const [identifications, setIdentifications] = useState({})
  const [showMap, setShowMap] = useState(false)
  const [alert, setAlert] = useState({ show: false })
  const [isQuote] = useState(useLocation().pathname.includes('/cotizaciones'))
  const [isVisit] = useState(useLocation().pathname.includes('/visita'))
  const [confirm, setConfirm] = useState(false)
  const [subscriptionModal, setSubscriptionModal] = useState({})
  const [inventory, setInventory] = useState([])
  const [action, setAction] = useState({
    create: false,
    get: false,
    roles: false,
    order: false,
    update: false,
    qUseTax: false,
    qBellTax: false,
    qCommission: false,
  })
  const [actionOrder, setActionOrder] = useState(false)
  const [details, setDetails] = useState([])
  const [clientBalanceModal, setClientBalanceModal] = useState({ show: false })
  const [clientNIT, setClientNIT] = useState('CF')
  const [clientCUI, setClientCUI] = useState('')
  const [retentionSV, setRetentionSV] = useState(false)
  const [applyCardSurcharge, setApplyCardSurcharge] = useState(automaticCardSurcharge)
  // Visita
  const [useFel, setUseFel] = useState(false)

  // QUOTES TAX CONFIGURATION
  const loadingIWT = useSelector(state =>
    loadingSelector([typeC.GET_WITHOUT_TAX_ON_QUOTE_FIELD])(state),
  )
  const itemsWithTaxField = useSelector(state => state.company.withoutTaxOnQuoteField)
  const [itemsWithTax, setItemsWithTax] = useState(true)

  const loadingTPB = useSelector(state =>
    loadingSelector([typeC.GET_TAX_PRESS_BELL_QUOTE])(state),
  )
  const taxPressBellField = useSelector(state => state.company.taxPressBellField)
  const [useTaxPressBell, setTaxPressBell] = useState(false)
  const [applyTaxPressBell, setApplyTaxPressBell] = useState(false)
  const [billPayments, setBillPayments] = useState(false)

  const loadingQC = useSelector(state =>
    loadingSelector([typeC.GET_COMMISSION_ON_QUOTE])(state),
  )
  const quoteCommissionField = useSelector(state => state.company.commissionOnQuoteField)
  const [useQuoteCommission, setUseQuoteCommission] = useState(0)
  const [applyCommission, setApplyCommission] = useState(false)

  const [session, saveSession, removeSession] = useSession('kolo-order')

  const [selectedItems, setSelectedItems] = useState([])
  const [initSelectionItems, setInitSelectionItems] = useState(false)

  const [paymentDate, setPaymentDate] = useState(null)
  const [itemsPriceError, setItemsPriceError] = useState({ show: false, items: [] })

  const isEdit = useLocation().pathname.includes('/editar')

  const canSetCardSurcharge =
    country.id === Country.GT &&
    order.payment?.value === PaymentType.CARD &&
    cardSurcharge > 0

  const taxExempt = order?.client?.taxExempt

  const getTotal = (param = 'subTotal') => {
    let response = 0
    inventory.forEach(s => {
      response += s[param] || 0
    })
    return response
  }

  const subTotal = getTotal()

  const itemsFormatted = inventory.map(item => ({
    quantity: 1,
    discount: 0,
    price: item.subTotal,
    taxRate: item.taxRate,
  }))

  const tax = useTaxBehavior(order?.felDocumentType?.value, itemsFormatted)
  const retention = retentionSV ? subTotal * 0.01 : 0
  const total = subTotal + tax - retention

  const totalMultiplePayments = order?.multiplePayments?.reduce((a, b) => a + b.amount, 0)
  const disabledCreate = !isQuote
    ? !order.payment ||
      (isVisit && !order.dispatch) ||
      (order.payment?.id === PaymentType.MULTIPLE && totalMultiplePayments !== total) ||
      (order.payment?.id === PaymentType.BANK_TRANSFER &&
        order.withDataBank &&
        !order.transferDetail) ||
      (order.felDocumentType.value === felDocumentType.EXPORTATION_INVOICE &&
        ((country.id === Country.SV &&
          (!order.kindOfPerson?.value || !order.economicActivity?.valueString)) ||
          !order.incoterm?.value))
    : false
  const [cashReceived, setCashReceived] = useState(0)

  useEffect(() => {
    const { id } = props.match.params
    if (id) dispatch(getSingleOrder(id))
    else setInitSelectionItems(true)

    dispatch(getWarehouseLite())
    dispatch(getCurrentUserRolesAction(module))
    dispatch(getPhaseTypes(6))
    dispatch(getCompanyField(companyFields.automaticCardSurcharge))
    dispatch(getCompanyField(companyFields.cardSurcharge))
    dispatch(getCompanyField(companyFields.useExportationInvoice))
    dispatch(getCompanyField(companyFieldsInvoice.automaticFEL))
    dispatch(getAllCategorizations(categoryType.CLIENTS.id))

    if (isVisit) dispatch(getCompanyField(90))
    if (!paymentMethods || paymentMethods.length <= 0) dispatch(getPaymentMethods())
    if (module === 2000) dispatch(getCompanyField(81))
    if (isQuote) {
      dispatch(getWithoutTaxOnQuoteField())
      dispatch(getCompanyField(119))
    }
  }, [])

  const renderCreditDateSelector = () => {
    return (
      <CustomDate
        onDayChange={date => {
          setPaymentDate(date)
        }}
        label={'Fecha de pago'}
        required
        disabledDays={{ before: new Date() }}
      />
    )
  }

  useEffect(() => {
    if (haveAnyValue(felActive?.value)) {
      setUseFel(felActive.value === 'true')
    }
  }, [haveAnyValue(felActive?.value)])

  useEffect(() => {
    // const total = getTotals()
    // setTotal(total)

    if (cashReceived !== total) {
      setChangeCash(0)
      setCashReceived(0)
    }

    if (order.felDocumentType.value === felDocumentType.EXPORTATION_INVOICE)
      dispatch(getIncoterm())
  }, [order.felDocumentType, retentionSV])

  useEffect(() => {
    if (!props.match.params.id && order.save && useDrafts)
      saveSession({ ...order, save: false })
  }, [order])

  useEffect(() => {
    const newClient =
      order.client?.id > 0 ? clients.find(c => c.id === order.client?.id) : defaultClient
    if (newClient) {
      setOrder({ ...order, client: newClient, save: false })
      setClientNIT(newClient.nit || 'CF')
    }
  }, [loadingClients])

  useEffect(() => {
    if (session !== undefined && !isEdit && useDrafts) {
      setAlert({
        ...handlerInfo(
          'Recuperación',
          '¿Desea recuperar la orden almacenada como borrador?',
        ),
        // TODO: Disabled due to incomplete process
        show: true,
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Sí',
        onConfirm: () => {
          saveSession(session)
          setOrder(session)
          setSelectedItems(session.list)
          setClientNIT(session.client?.nit)
          setAlert({ show: false })
        },
        onCancel: () => {
          removeSession()
          setAlert({ show: false })
        },
      })
    }
  }, [session])

  useEffect(() => {
    if (loadingCreate) setAction({ ...action, create: true })
    else if (action.create) {
      setAction({ ...action, create: false })
      if (hasErrorCreate) {
        setAlert({
          ...handlerError(
            hasErrorCreate?.message || errorCreate?.message || 'Error al crear la orden',
          ),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        removeSession()
        setAlert({
          ...handlerSuccess('Operación exitosa'),
          onConfirm: () => {
            setAlert({ show: false })
            setConfirm(false)
            setSubscriptionModal({ show: false })
            if (isVisit) history.push('/distribucion/ordenes/visita')
            else if (isQuote) history.push('/distribucion/cotizaciones/false')
            else history.push('/distribucion/ordenes/false')
            setTimeout(() => {
              dispatch(onSetModalOrder(singleOrder.id))
            }, 500)
          },
        })
      }
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingUpdate) setAction({ ...action, update: true })
    else if (action.update) {
      setAction({ ...action, update: false })
      if (hasErrorUpdate) {
        setAlert({
          ...handlerError(errorUpdate.message || 'Error al crear la orden'),
          onConfirm: () => setAlert({ show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Orden actualizada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ show: false })
            setConfirm(false)
            setSubscriptionModal({ show: false })
            if (isVisit) history.push('/distribucion/ordenes/visita')
            else if (isQuote) history.push('/distribucion/cotizaciones/false')
            else history.push('/distribucion/ordenes/false')
            setTimeout(() => {
              dispatch(onSetModalOrder(singleOrder.id))
            }, 500)
          },
        })
      }
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (loadingSingleOrder) {
      setActionOrder(true)
    } else if (actionOrder) {
      let orderResponse = singleOrder
      const { id } = props.match.params
      setActionOrder(false)

      let deliveryDate = orderResponse.enabledAt
      if (!deliveryDate) deliveryDate = parseDate(moment())
      else deliveryDate = parseDate(moment(deliveryDate))

      let warehouse = { label: '- No seleccionado -', value: null }

      if (orderResponse.warehouse) {
        warehouse.label = orderResponse.warehouse.name
        warehouse.value = orderResponse.warehouse.id
      } else if (order.warehouse) warehouse = order.warehouse

      const contact = orderResponse.contactName
        ? {
            value: orderResponse.contactName,
            label: orderResponse.contactName,
            name: orderResponse.contactName,
          }
        : null

      dispatch(getClientContacts(orderResponse?.client?.id))

      const identifications = {}
      orderResponse.client.identifications?.forEach(
        identification =>
          (identifications[identification.identificationTypeId] = identification),
      )
      setIdentifications(identifications)

      let extraParams = {}
      if (orderResponse.FELConfiguration) {
        extraParams = formatFELConfiguration(orderResponse.FELConfiguration)
      }

      const newOrder = {
        ...orderResponse,
        id,
        client: {
          ...orderResponse.client,
          value: orderResponse.client.id,
          label: orderResponse.client.companyName,
        },
        deliveryDate,
        enabledAt: new Date(orderResponse.enabledAt),
        warehouse: warehouse,
        products: [],
        presale: false,
        payment: {
          ...orderResponse?.paymentTypeData,
          value: orderResponse?.paymentTypeData?.id,
          label: orderResponse?.paymentTypeData?.name,
        },
        contact: contact,
        felDocumentType: felDocumentTypes.find(
          d => d.value === (orderResponse.felDocumentType || 1),
        ),
        ...extraParams,
      }

      setOrder(newOrder)

      setDetails(orderResponse.details)

      setClientNIT(orderResponse.client.nit || 'CF')
      setBillPayments(orderResponse.billPayments)
      setInitSelectionItems(true)
    }
  }, [loadingSingleOrder])

  useEffect(() => {
    if (loadingIWT) setAction({ ...action, qUseTax: true })
    else if (action.qUseTax) {
      setAction({ ...action, qUseTax: false })
      setItemsWithTax(itemsWithTaxField?.value === 'false')
      dispatch(getTaxPressBellQuoteField())
    }
  }, [loadingIWT])

  useEffect(() => {
    if (loadingTPB) setAction({ ...action, qBellTax: true })
    else if (action.qBellTax) {
      setAction({ ...action, qBellTax: false })
      setTaxPressBell(taxPressBellField?.value === 'true')
      setApplyTaxPressBell(true)
      dispatch(getCommissionOnQuoteField())
    }
  }, [loadingTPB])

  useEffect(() => {
    if (loadingQC) setAction({ ...action, qCommission: true })
    else if (action.qCommission) {
      setAction({ ...action, qCommission: false })
      setUseQuoteCommission(Number(quoteCommissionField?.value || 0))
      setApplyCommission(true)
    }
  }, [loadingQC])

  useEffect(() => {
    setApplyCardSurcharge(automaticCardSurcharge)
  }, [automaticCardSurcharge])

  const handleChangeClient = (client, identifications = {}) => {
    const id = client.clientInfo?.id || client.id
    const label =
      client.clientName || client.clientInfo?.companyName || client.companyName
    const nit = client.NIT || client.clientInfo?.nit || client.nit
    const cui = client.clientInfo?.cui || client.cui
    const email = client.clientInfo?.email || client.email
    const address = client.clientInfo?.address || client.address

    const newClient = {
      ...client,
      id,
      label,
      nit,
      cui,
      email,
      address,
      balance: null,
      save: true,
      new: !client.id,
    }

    setClientNIT(nit)
    setClientCUI(cui)
    setOrder({
      ...order,
      save: true,
      contact: null,
      client: newClient,
    })
    setIdentifications(identifications)

    if (newClient.id) dispatch(getClientContacts(newClient.id))
  }

  const changeText = ({ target }) => {
    const { value, name } = target
    order.client[name] = name === 'nit' || name === 'invoice' ? trim(value) : value
    if (name === 'nit' && order.client[name])
      dispatch(getProviderByNit(order.client[name]))
    setOrder({ ...order, save: true })
  }

  const validateOrder = async subscription => {
    let { client, payment, warehouse, deleted, description } = order
    let items = []

    let index = 0
    const isExportation =
      order.felDocumentType.value === felDocumentType.EXPORTATION_INVOICE

    const withInvalidePrice = []

    inventory.forEach(product => {
      const {
        detailId,
        purchase,
        origin,
        commentary,
        save,
        includeIva,
        phraseReference,
        listVariations,
        listLocations,
        // Validations of price
        productPrice, // default price of item
        activeUpdatable, // Can update item price
        price, // price of item setting by user,
        name,
        list,
      } = product

      if (Number(price) !== Number(productPrice) && !activeUpdatable) {
        if (list?.findIndex(x => x.price === Number(price)) === -1) {
          withInvalidePrice.push({
            name,
            defaultPrice: productPrice,
            price,
          })
        }
      }

      let editable = 1
      product?.line?.forEach(itemLIne => {
        let exist = -1
        if (!fieldRepeat && !listVariations && !listLocations && product.productId !== 1)
          exist = items.findIndex(x => x.product === itemLIne.productId)

        if (itemLIne.quantity > 0 && exist === -1) {
          const item = {
            detailId: detailId ? detailId : null,
            product: itemLIne.productId,
            quantity: itemLIne.quantity,
            discount: itemLIne.discount,
            purchase: purchase ? purchase.value : null,
            origin,
            editable,
            commentary,
            includeIva,
            phraseReference: phraseReference ? phraseReference.value : null,
            price:
              itemLIne.priceInSale !== undefined ? itemLIne.priceInSale : product.price,
            save,
            felItemTypeId: product.felItemTypeId,
            variations: listVariations
              ? listVariations.map(variation => variation.id)
              : [],
            listIdLocations: listLocations
              ? listLocations.map(location => location.id)
              : [],
            edited: product.edited !== null ? product.edited : false,
            index: index++,
            originDocumentId: product.originDocumentId,
            originDocumentTypeId: product.originDocumentTypeId,
          }
          items.push(item)
        }
      })
    })

    if (withInvalidePrice.length > 0) {
      setConfirm(false)
      dispatch(
        showAlert({
          ...handlerError('Los siguientes ítems tienen precios incorrectos'),
          onConfirm: () => {
            setItemsPriceError({ show: true, items: withInvalidePrice })
          },
        }),
      )
      return
    }

    deleted.forEach(product => {
      const { detailId } = product

      const item = {
        detailId: detailId ? detailId : null,
        deleted: true,
      }
      items.push(item)
    })

    let requestQuoteTax = null
    if (isQuote) {
      requestQuoteTax = {
        commission: getQuoteCommissionValue(),
        tax: getTotalWithCommission(true, true, false),
        taxPressBell: getTotalWithCommission(true, false, true),
      }
    }

    let clientData = {
      ...order.client,
      nit: clientNIT || client?.nit || 'CF',
      client: isNaN(Number(client.id)) ? -1 : client.id,
      clientName: client.label,
      newClient: client.new,
    }

    const warehouseId = warehouse?.value || warehouse?.id

    if (!warehouseId)
      setAlert({
        ...handlerError('Seleccione una bodega para la orden'),
        onConfirm: () => setAlert({ show: false }),
      })

    if (
      taxExempt &&
      (!order.taxExemptValues ||
        !haveAnyValue(order.taxExemptValues.purchaseNumber) ||
        !haveAnyValue(order.taxExemptValues.register))
    ) {
      dispatch(
        showAlert(
          handlerError(
            'Es necesario llenar los datos de Orden de compra exenta y la Constancia de registro',
          ),
        ),
      )
      return
    }

    // const configurationFELInvoices = getTaxExemptValues()
    const configurationFELInvoices = taxExempt
      ? getFELConfigurationByTaxExemptValues(order.taxExemptValues)
      : null
    const felDocumentTypeId = taxExempt
      ? felDocumentType.INVOICE_WITHOUT_IVA
      : order.felDocumentType.value

    let cashReceivedRequest = 0,
      cashReturnedRequest = 0

    if (order.payment?.id === PaymentType.CASH) {
      if (haveAnyValue(cashReceived) && cashReceived > 0) {
        cashReceivedRequest = cashReceived
        cashReturnedRequest = changeCash
      }
    }

    if (order.id && !isVisit) {
      const request = {
        number: order.number,
        paymentType: payment.value,
        transferDetail: order.transferDetail,
        commission: requestQuoteTax?.commission,
        tax: requestQuoteTax?.tax,
        taxPressBell: requestQuoteTax?.taxPressBell,
        contactName: order?.contact?.name,
        productDetail: items,
        felDocumentType: felDocumentTypeId,
        identifications: Object.values(identifications),
        retentionSV,
        ...clientData,
        description,
        applyCardSurcharge: canSetCardSurcharge && applyCardSurcharge,
        multiplePayments: order.multiplePayments,
        warehouse: warehouseId,
        kindOfPerson: isExportation ? order.kindOfPerson : undefined,
        economicActivity: isExportation ? order.economicActivity : undefined,
        incoterm: isExportation ? order.incoterm : undefined,
        configurationFELInvoices,
        cashReceived: cashReceivedRequest,
        cashReturned: cashReturnedRequest,
      }
      dispatch(updateOrderProducts(order.id, request))
      return
    }

    const gpsResponse = await validateGeoLocation(
      { isGeolocationAvailable, isGeolocationEnabled, coords },
      null,
      null,
      null,
      true,
    )

    const longitude = client.longitude || gpsResponse?.cc?.latitude || -90.55
    const latitude = client.latitude || gpsResponse?.cc?.longitude || 14.55

    const request = {
      productDetail: items,
      paymentType: isQuote ? 2 : payment.value,
      warehouse: warehouseId,
      presale: false,
      deliveryDate: order?.enabledAt?.valueOf() || new Date().valueOf(),
      paymentDateOrderCredit:
        order.payment?.id === 2 ? paymentDate?.valueOf() : order?.enabledAt?.valueOf(),
      delivered: order.delivered ? order.delivered.value : 0,
      origin: 1,
      ...clientData,
      latitude,
      longitude,
      delivererCompany: order.delivererCompany ? order.delivererCompany.value : null,
      linked: false,
      quote: isQuote,
      number: order.number,
      subscription,
      requestQuoteTax,
      contact: order?.contact,
      advance: order.advance || 0,
      billPayments,
      finishDate: isVisit && order.finishDate ? order.finishDate.valueOf() : null,
      felDocumentType: felDocumentTypeId,
      identifications: Object.values(identifications),
      retentionSV,
      description,
      applyCardSurcharge: canSetCardSurcharge && applyCardSurcharge,
      multiplePayments: order.multiplePayments,
      transferDetail: order.transferDetail,
      kindOfPerson: isExportation ? order.kindOfPerson : undefined,
      economicActivity: isExportation ? order.economicActivity : undefined,
      incoterm: isExportation ? order.incoterm : undefined,
      configurationFELInvoices,
      cashReceived: cashReceivedRequest,
      cashReturned: cashReturnedRequest,
    }

    if (isVisit) {
      if (canChooseFEL) request.dontCertificate = !useFel
      else if (felData.value === '3') request.dontCertificate = true
      request.dispatchMethod = order?.dispatch?.value || 1
      dispatch(onConfirmOrderVisit(order?.id, request))
    } else {
      if (paymentDate === null && order?.payment !== null && order?.payment?.id === 2) {
        dispatch(showAlert(handlerError('Debe introducir la fecha de pago')))
      } else {
        dispatch(createNewOrder(request))
      }
    }
  }

  const getQuoteCommissionValue = () => {
    let response = 0
    if (useQuoteCommission > 0 && applyCommission) {
      const p = useQuoteCommission / 100
      response = getNumber(p * getTotal())
    }

    return response
  }

  const getTotalWithCommission = (ignoreTotal, withIVA, withPressBell) => {
    let response = 0
    if (!ignoreTotal) response = getNumber(getTotal() + getQuoteCommissionValue())

    if (!itemsWithTax && withIVA)
      response += getNumber((getTotal() + getQuoteCommissionValue()) * 0.12)

    if (useTaxPressBell && withPressBell && applyTaxPressBell)
      response += getNumber((getTotal() + getQuoteCommissionValue()) * 0.005)

    return response
  }

  /**
   * Renders the IVA and total calculations.
   * @returns {JSX.Element} Monetary calculation inputs
   */
  const monetaryCalculationInputs = () => {
    return (
      <>
        {tax > 0 && (
          <>
            <Col xl={6} md={6} xs={12}>
              <FormText
                disabled
                label={'Sub total'}
                prependMoneySymbol
                value={toMoney(subTotal)}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <FormText disabled label={'IVA'} prependMoneySymbol value={toMoney(tax)} />
            </Col>
          </>
        )}

        <Col xl={6} md={6} xs={12}>
          <FormText
            disabled
            label={retentionSV ? 'Total con IVA' : 'Total'}
            prependMoneySymbol
            value={toMoney(total + retention)}
          />
        </Col>

        {retentionSV && (
          <>
            <Col xl={6} md={6} sm={12}>
              <FormText
                disabled
                label={'Retención'}
                prependMoneySymbol
                value={toMoney(retention)}
              />
            </Col>

            <Col xl={6} md={6} sm={12}>
              <FormText disabled label={'Total'} prependMoneySymbol value={total} />
            </Col>
          </>
        )}
      </>
    )
  }

  const documentTypes = (
    <>
      {country.id === Country.SV && (
        <>
          <Col xl={12}>
            <label className={'ftf-form-label'}>
              Tipo de documento
              <span style={{ color: 'red', marginRight: '2px' }}>*</span>
            </label>

            <SelectFiscalDocumentSV
              value={order.felDocumentType.value}
              onChange={value => {
                setRetentionSV(false)
                setOrder({ ...order, felDocumentType: { value } })
              }}
              disabled={loadingCreate}
              useExportation={useExportationInvoice?.value === 'true'}
            />
          </Col>
          {order.felDocumentType.value === 1 && total > 100 && (
            <Col xl={12} xs={12}>
              <Switch
                topLabel
                label={'Retener el 1%'}
                info={
                  'Retiene el 1% del total de la venta, opción especial para clientes tipo gubernamentales'
                }
                mt={2}
                checked={retentionSV}
                changeValue={value => {
                  setRetentionSV(value)
                }}
              />
            </Col>
          )}
          {order.felDocumentType.value === 11 && (
            <>
              <Col xl={12} md={12} xs={12}>
                <FormText
                  required
                  label={'Actividad económica'}
                  value={order.economicActivity?.valueString}
                  onChange={({ target }) =>
                    setOrder({
                      ...order,
                      economicActivity: { valueString: target.value },
                    })
                  }
                />
              </Col>

              <Col xl={12}>
                <Select
                  required
                  label={'Tipo de persona'}
                  value={order.kindOfPerson}
                  placeholder={'Seleccione el tipo'}
                  isSearchable={false}
                  options={[
                    {
                      label: 'Persona Natural',
                      value: 1,
                    },
                    {
                      label: 'Persona Jurídica',
                      value: 2,
                    },
                  ]}
                  onChange={e =>
                    setOrder({ ...order, kindOfPerson: { ...e, valueString: e.label } })
                  }
                />
              </Col>
            </>
          )}
        </>
      )}
      {country.id === Country.GT && useExportationInvoice?.value === 'true' && (
        <Col xl={12}>
          <SwitchV2
            label={`Factura de exportación`}
            info={'Esta venta sera una factura de exportación.'}
            checked={order.felDocumentType?.value === 11}
            onChange={checked => {
              setOrder({
                ...order,
                felDocumentType: { value: checked ? 11 : 1 },
              })
            }}
          />
        </Col>
      )}

      {order.felDocumentType.value === felDocumentType.EXPORTATION_INVOICE && (
        <Col xl={12}>
          <Select
            required
            label={'Incoterm'}
            value={order.incoterm}
            placeholder={'Seleccione el tipo'}
            isSearchable={false}
            options={incoterm}
            onChange={incoterm =>
              setOrder({
                ...order,
                incoterm,
              })
            }
          />
        </Col>
      )}

      {monetaryCalculationInputs()}

      {/*{[Country.SV, Country.HN].some(c => c === country.id) &&
        monetaryCalculationInputs()}*/}
    </>
  )

  const handleCreatedClient = (newClient, identifications) => {
    setOrder({
      ...order,
      client: {
        ...newClient,
        label: `${newClient.code} - ${newClient.companyName}`,
        value: newClient.id,
      },
    })
    setIdentifications(identifications)
    setClientNIT(newClient.nit)
    setClientCUI(newClient.cui)
  }

  const getDateForSave = () => {
    let actualDate = ''

    if (order.payment !== undefined) {
      if (paymentDate !== '' && order.payment.id === 2) {
        actualDate = formatDateFromMillis(paymentDate)
      } else {
        actualDate = formatDateFromMillis(
          order?.enabledAt?.valueOf() || new Date().valueOf(),
        )
      }
    }

    return actualDate
  }

  return (
    <div>
      <Title
        title={!order?.id ? 'Nuevo Registro' : `Registro: ${order?.number}`}
        action
        actionTitle={'Crear nuevo cliente'}
        onClick={() =>
          dispatch(
            setModalCreateClient({ show: true, onCreateOption: handleCreatedClient }),
          )
        }
      />
      <Card>
        <Row>
          <Col xs={12} md={3}>
            <SelectClient
              actionType={clientActions.GET_CLIENT_POLYGON}
              className="mt-3"
              onChange={(client, identifications) =>
                handleChangeClient(client, identifications)
              }
              value={order.client}
              identifications={identifications}
              onCreateOption={handleCreatedClient}
              disabled={loadingClients || isVisit}
              allClients={!isVisit && canViewAll}
              init
            />
            {order.client?.balance && order.client?.balance > 0 && seeBalances ? (
              <div
                className="ft-sub left mt-2 ml-2"
                style={{
                  cursor: 'pointer',
                  fontWeight: 500,
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  setClientBalanceModal({ show: true })
                  dispatch(getOrderBalanceByCustomers(order.client?.value))
                }}>
                Saldo: {formatNumberWithCommas(order.client?.balance)}, Ver ordenes
                pendientes de pago(s)
              </div>
            ) : (
              <div />
            )}
            {order.client?.vouchers > 0 ? (
              <div
                className="ft-sub left mt-2 ml-2"
                style={{
                  fontWeight: 500,
                }}>
                Saldo a favor: {formatNumberWithCommas(order.client.vouchers)}
              </div>
            ) : (
              <div />
            )}
          </Col>
          {country.id === Country.GT ? (
            <>
              <Col xs={12} md={3}>
                <IdentificationField
                  name={'nit'}
                  identificationTypeId={identificationTypesEnum.NIT_GT}
                  value={clientNIT}
                  onChange={setClientNIT}
                  onSearch={(client, identifications) =>
                    handleChangeClient(client, identifications)
                  }
                  disabled={order?.status === 3}
                  required
                />
              </Col>
              <Col xs={12} md={3}>
                <IdentificationField
                  name={'dpi'}
                  identificationTypeId={identificationTypesEnum.CUI}
                  value={clientCUI}
                  onChange={setClientCUI}
                  onSearch={(client, identifications) =>
                    handleChangeClient(client, identifications)
                  }
                  disabled={order?.status === 3}
                />
              </Col>
            </>
          ) : (
            identificationTypes
              .filter(identificationType => identificationType.countryId !== null)
              .map(identificationType => (
                <Col key={identificationType.id} xs={12} md={3}>
                  <IdentificationField
                    identificationTypeId={identificationType.id}
                    value={identifications[identificationType.id]?.value}
                    onChange={value => {
                      identifications[identificationType.id] = {
                        identificationTypeId: identificationType.id,
                        value,
                      }
                      setIdentifications(identifications)

                      const newOrder = {
                        ...order,
                        save: true,
                      }
                      setOrder(newOrder)
                    }}
                    disabled={order?.status === 3}
                  />
                </Col>
              ))
          )}
          {identificationTypes
            .filter(identificationType => identificationType.countryId === null)
            .map(identificationType => (
              <Col key={identificationType.id} xs={12} md={3}>
                <IdentificationField
                  identificationTypeId={identificationType.id}
                  value={identifications[identificationType.id]?.value}
                  onChange={value => {
                    identifications[identificationType.id] = {
                      identificationTypeId: identificationType.id,
                      value,
                    }
                    setIdentifications(identifications)

                    const newOrder = {
                      ...order,
                      save: true,
                    }
                    setOrder(newOrder)
                  }}
                  disabled={order?.status === 3}
                />
              </Col>
            ))}
          <Col xs={12} md={3}>
            <FormText
              label={'Correo'}
              type={'email'}
              name={'email'}
              value={order.client ? order.client.email || '' : ''}
              prepend={<Icon icon={faEnvelope} />}
              disabled={order?.status === 3}
              onChange={changeText}
            />
          </Col>
          <Col xs={12} md={3}>
            <ClientContacts
              show
              id={order?.client?.id}
              optionMode
              value={order?.contact}
              onChange={value => {
                let newContact = null
                if (value) newContact = { ...value, label: value.name }
                setOrder({ ...order, contact: newContact })
              }}
              disabledSelect={order?.status === 3}
            />
          </Col>
          <Col xs={12} md={3} style={{ marginTop: '45px' }}>
            <Button
              className={'align-bottom'}
              onClick={() => {
                if (order.client?.id) {
                  dispatch(
                    setModalEditClient({
                      show: true,
                      id: order?.client?.id,
                      onSave: (client, identifications) => {
                        handleChangeClient(
                          {
                            ...client,
                            value: client.id,
                            label: client.companyName,
                          },
                          identifications,
                        )
                      },
                    }),
                  )
                } else {
                  dispatch(
                    setModalCreateClient({
                      show: true,
                      onCreateOption: handleCreatedClient,
                    }),
                  )
                  dispatch(
                    onCreateClientStep({
                      companyName: order.client.name,
                      businessName: order.client.name,
                      identifications,
                      email: order.client.email,
                      address: order.client.address,
                      nit: order.client.nit,
                      cui: order.client.cui,
                    }),
                  )
                }
              }}
              icon={order.client?.id ? faEdit : faSave}>
              {order.client?.id ? 'Editar' : 'Guardar'}
            </Button>
          </Col>
        </Row>
      </Card>

      {!initSelectionItems ? (
        <Loading show />
      ) : (
        <div>
          <ItemSelectionTable
            warehouseId={order.warehouse?.id || order.warehouse?.value}
            warehouseName={order.warehouse?.label}
            type={itemTypes.SELL}
            onSelected={(selected, deleted) => {
              setInventory(selected)
              setOrder({
                ...order,
                save: selected.length > 0,
                list: inventory,
                deleted,
              })
              //setSelected(selected)
            }}
            balancePermission
            onWarehouse={warehouse => {
              setOrder({ ...order, warehouse })
            }}
            selectedItems={selectedItems}
            details={details}
            warehouseDisabled={order?.status === 3}
          />
        </div>
      )}

      <FABV2
        show={
          inventory.length > 0 &&
          inventory.every(i => i.value !== undefined && i.haveQuantities) &&
          order.client
        }
        onClick={() => {
          setCashReceived(0)
          setConfirm(true)
        }}
        title={'Continuar'}
        icon={faCheckDouble}
      />

      <Modal show={showMap} size={'lg'} centered onHide={() => setShowMap(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Mapa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Geocoding
            withoutTitle
            editable={order.client && !order.client.value}
            defaultAddress={order.client?.address}
            auto
            getData={data => {
              const { lat, lng } = data.latLng
              if (order.client)
                handleChangeClient({
                  ...order.client,
                  address: data.address,
                  latitude: lat,
                  longitude: lng,
                })
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={confirm} size={'lg'} centered onHide={() => setConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Concluir {order?.id ? 'Edición' : 'Creación'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <FormText
                label={'Descripción'}
                type={'text'}
                name={'description'}
                value={order.description}
                prepend={<Icon icon={faBook} />}
                changeValue={v => setOrder({ ...order, description: v })}
              />
            </Col>
            <Col xl={12}>
              <FormText
                label={`Código de ${isQuote ? 'cotización' : 'venta'}`}
                type={'text'}
                name={'number'}
                value={order.number}
                onChange={({ target }) => setOrder({ ...order, number: target.value })}
              />
            </Col>

            {country.id === Country.HN && taxExempt && (
              <Col xl={12}>
                <ClientTaxExempt
                  values={order.taxExemptValues}
                  onChange={taxExemptValues => setOrder({ ...order, taxExemptValues })}
                />
              </Col>
            )}

            <Col xl={12}>
              {!isQuote ? (
                <Row>
                  <Col xl={12}>
                    <Select
                      required
                      label={'Método de pago'}
                      value={order.payment}
                      placeholder={'Seleccione un método de pago'}
                      isSearchable={false}
                      options={paymentMethods}
                      disabled={order?.status === 3}
                      onChange={e => setOrder({ ...order, payment: e })}
                    />
                  </Col>

                  {order.payment?.id === 2 && (
                    <Col xl={12}>{renderCreditDateSelector()}</Col>
                  )}

                  {order.payment?.value === PaymentType.BANK_TRANSFER && (
                    <BankDetails
                      onUpdate={transferDetail => {
                        setOrder({
                          ...order,
                          transferDetail,
                        })
                      }}
                      onActive={active => setOrder({ ...order, withDataBank: active })}
                    />
                  )}
                  {canSetCardSurcharge && (
                    <Col xl={12}>
                      <SwitchV2
                        label={`Recargo (${cardSurcharge}%)`}
                        info={'El recargo se aplica al total de la factura.'}
                        checked={applyCardSurcharge}
                        onChange={setApplyCardSurcharge}
                      />
                    </Col>
                  )}

                  {isVisit && (
                    <>
                      <Col xl={12}>
                        <Select
                          required
                          label={'Método de despacho'}
                          options={[
                            {
                              label: 'Completo',
                              value: orderDispatchType.confirmWithFullDispatch,
                              show: canConfirmVisitCompleteInventory,
                            },
                            {
                              label: 'Parcial',
                              value: orderDispatchType.confirmWithPartialDispatch,
                              show: canConfirmVisitParcialInventory,
                            },
                            {
                              label: 'Pendiente de despacho',
                              value: orderDispatchType.confirmWithPendingDispatch,
                              show: canConfirmVisitParcialInventory,
                            },
                          ].filter(o => o.show)}
                          value={order.dispatch}
                          onChange={v => setOrder({ ...order, dispatch: v })}
                        />
                      </Col>
                      <Col xl={12}>
                        {(order.dispatch?.value === 3 || order.dispatch?.value === 4) && (
                          <CustomDate
                            label={'Fecha de despacho (Opcional)'}
                            value={order.finishDate}
                            onDayChange={finishDate => setOrder({ ...order, finishDate })}
                            disabledDays={[]}
                          />
                        )}
                      </Col>
                    </>
                  )}

                  {documentTypes}

                  {order.payment?.id === PaymentType.CASH && (
                    <>
                      <Col xl={6} md={6}>
                        <FormText
                          label={'Efectivo recibido'}
                          type={'number'}
                          step=".01"
                          prependMoneySymbol
                          value={cashReceived}
                          onChange={e => {
                            setChangeCash(e.target.value - total)
                            setCashReceived(e.target.value)
                          }}
                          error={
                            changeCash < 0 &&
                            `El efectivo recibido debe ser mayor o igual al total (${toMoney(
                              total,
                            )})`
                          }
                        />
                      </Col>
                      <Col xl={6} md={6}>
                        <FormText
                          readonly
                          label={'Cambio'}
                          type={'text'}
                          disabled
                          prependMoneySymbol
                          value={formatNumberWithCommas(changeCash > 0 ? changeCash : 0)}
                        />
                      </Col>
                    </>
                  )}
                  {order.payment?.id === PaymentType.MULTIPLE && (
                    <MultiplePayments
                      save={payments => {
                        setOrder({
                          ...order,
                          multiplePayments: payments.filter(
                            payment => payment.amount > 0,
                          ),
                        })
                      }}
                      setPaymentDate={setPaymentDate}
                      maxAmount={total}
                    />
                  )}
                </Row>
              ) : (
                <Row>
                  <Col xl={12}>
                    <FormText
                      label={'Anticipo de la cotización (%)'}
                      type={'number'}
                      info={
                        'Porcentaje mínimo de anticipo para poder confirmar la cotización'
                      }
                      value={order.advance || 0}
                      onChange={({ target }) => {
                        let advance = Number(target.value)
                        if (advance > 100) advance = 100
                        if (advance < 0) advance = 0

                        setOrder({ ...order, advance })
                      }}
                      disabled={order.id}
                    />
                  </Col>
                  <Col xl={12}>
                    <FormText
                      label={'Anticipo de la cotización'}
                      info={
                        'Cantidad mínima de anticipo para poder confirmar la cotización'
                      }
                      value={
                        ((country.id === Country.SV
                          ? total
                          : getTotalWithCommission(false, true, true)) *
                          (order.advance || 0)) /
                        100
                      }
                      prependMoneySymbol
                      onChange={({ target }) => {
                        const max =
                          country.id === Country.SV
                            ? total
                            : getTotalWithCommission(false, true, true)

                        let advanceAmount = Number(target.value)

                        if (advanceAmount > max) advanceAmount = max
                        if (advanceAmount < 0) advanceAmount = 0

                        const advance = (advanceAmount * 100) / max
                        setOrder({ ...order, advance })
                      }}
                      disabled={order.id}
                    />
                  </Col>

                  {country.id === Country.SV ? (
                    documentTypes
                  ) : (
                    <>
                      <Col xl={12}>
                        <FormText
                          label={`Total de ítems en la cotización`}
                          value={getTotal()}
                          prependMoneySymbol
                          disabled
                        />
                      </Col>
                      {useQuoteCommission > 0 && (
                        <Col xl={12}>
                          <FormText
                            label={`Comisión de agencia % ${useQuoteCommission}`}
                            value={getQuoteCommissionValue()}
                            prependMoneySymbol
                            disabled
                            append={
                              <div className={'ml-1'}>
                                <Switch
                                  mt={2}
                                  checked={applyCommission}
                                  changeValue={value => setApplyCommission(value)}
                                />
                              </div>
                            }
                            atext
                          />
                        </Col>
                      )}
                      <Col xl={12}>
                        <FormText
                          disabled
                          label={'Sub total'}
                          prependMoneySymbol
                          value={getTotalWithCommission()}
                        />
                      </Col>
                      {!itemsWithTax && (
                        <Col xl={12}>
                          <FormText
                            disabled
                            prependMoneySymbol
                            label={'IVA'}
                            value={getTotalWithCommission(true, true)}
                          />
                        </Col>
                      )}
                      {useTaxPressBell && (
                        <Col xl={12}>
                          <FormText
                            disabled
                            prependMoneySymbol
                            label={'Timbre de prensa'}
                            value={getTotalWithCommission(true, false, true)}
                            atext
                            append={
                              <div className={'ml-1'}>
                                <Switch
                                  mt={2}
                                  checked={applyTaxPressBell}
                                  changeValue={value => setApplyTaxPressBell(value)}
                                />
                              </div>
                            }
                          />
                        </Col>
                      )}
                      <Col xl={12}>
                        <FormText
                          disabled
                          prependMoneySymbol
                          label={'Total a cotizar'}
                          value={getTotalWithCommission(false, true, true)}
                        />
                      </Col>
                    </>
                  )}

                  {allowBillPayments.value === 'true' && (
                    <Col xl={12}>
                      <Switch
                        topLabel
                        label={'Facturar abonos'}
                        info={
                          'Al activar esta opción se facturarán los abonos de la orden. La orden será al crédito y no se facturará al confirmarse.'
                        }
                        mt={2}
                        checked={billPayments}
                        changeValue={value => setBillPayments(value)}
                        disabled={order.id}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {isVisit && canChooseFEL && felData.value !== '3' && (
            <Switch
              name={'fel'}
              checked={useFel}
              onChange={() => {
                setUseFel(!useFel)
              }}
              label={'User FEL'}
              info={'La confirmación de la visita con venta sera con FEL'}
              placement={'top'}
            />
          )}
          <Button
            color={'primary'}
            disabled={disabledCreate}
            loading={loadingCreate || loadingUpdate}
            onClick={() => validateOrder()}
            icon={faSave}>
            {isVisit
              ? canChooseFEL && felData.value !== '3'
                ? 'Confirmar'
                : felData.value === '3'
                ? 'Confirmación sin FEL'
                : 'Confirmación con FEL'
              : order.id
              ? `Actualizar productos`
              : isQuote
              ? 'Crear'
              : `Crear para: ${getDateForSave()}`}
          </Button>
        </Modal.Footer>
      </Modal>

      <CreateSubscription
        show={subscriptionModal.show}
        modal
        amount={subscriptionModal.amount}
        onHide={() => setSubscriptionModal({ show: false, amount: 0 })}
        type={1}
        onCreate={data => {
          if (order.global) data.ownerType = 2
          else data.ownerType = 1
          validateOrder({ ...data, startDate: data.startDate.valueOf() })
        }}
        subscriptionType={1}
      />

      <Modal
        show={clientBalanceModal.show}
        centered
        size={'lg'}
        onHide={() => setClientBalanceModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Cuentas por pagar pendientes de pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            loading={loadingOB}
            items={balance.orders}
            mobileAuto
            storageKey={`infoClient`}
            headers={[
              {
                label: 'Código',
                show: true,
                value: ['number'],
                type: 'text',
                className: 'mini',
              },
              {
                label: 'Fecha',
                show: true,
                value: ['enabledAt'],
                type: 'date',
                className: 'mini',
              },
              {
                label: 'Total',
                show: true,
                value: ['total'],
                type: 'currency',
                className: 'mini',
              },
              {
                label: 'Pendiente',
                show: true,
                value: ['balance'],
                type: 'currency',
                className: 'mini',
              },
              {
                config: true,
                show: true,
                label: '',
                className: 'mini',
                custom: item => (
                  <Icon
                    icon={faBook}
                    tooltip={'Ver detalle'}
                    style={{ marginRight: 4 }}
                    onClick={() => {
                      dispatch(onSetModalOrder(item.orderId))
                    }}
                  />
                ),
              },
            ]}
          />
        </Modal.Body>
      </Modal>

      <OrderItemsPriceWithError
        show={itemsPriceError.show}
        items={itemsPriceError.items}
        onHide={() => setItemsPriceError({ show: false, items: [] })}
      />

      <Alert {...alert} />
    </div>
  )
}

export default geolocated({
  positionOptions: { enableHighAccuracy: true, userDecisionTimeout: 5000 },
})(CreateOrder)
