import { CookieEnum } from 'src/enums/cookies'

export function deleteCookie(name: CookieEnum) {
  try {
    const maxAgeInSeconds = 12 * 60 * 60 * 24
    if (name === CookieEnum.userToken) {
      document.cookie = `authToken=; expires=${maxAgeInSeconds}; path=/; domain=.kolo-app.com; Secure; SameSite=None`
      document.cookie = `userToken=; expires=${maxAgeInSeconds}; path=/;Secure; SameSite=None`
    }
    document.cookie = `${name}=; expires=${maxAgeInSeconds}; path=/;Secure; SameSite=None`
  } catch (error) {
    throw new Error(`Error deleting cookie: ${error}`)
  }
}
