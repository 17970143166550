import { CookieEnum, CookieExpirationHours } from 'src/enums/cookies'

interface TokenV2 {
  tokenSigned: string
}

/**
 * Get token from /api/v1-login
 * @param token
 */
async function getToken(token) {
  const response = await fetch('/api/v1-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  })

  if (!response.ok) {
    throw new Error('Failed to get token')
  }

  const data: TokenV2 = await response.json()
  return data
}

/**
 * Set cookie by name and value with expiration time in hours from CookieExpirationHours
 * @param name
 * @param value
 */
async function setCookie(name: CookieEnum, value: string) {
  const hoursToExpire = CookieExpirationHours[name]
  const expirationTime = new Date(
    Date.now() + hoursToExpire * 60 * 60 * 1000,
  ).toUTCString()

  if (name === CookieEnum.userToken) {
    document.cookie = `authToken=${value}; expires=${expirationTime}; path=/; domain=.kolo-app.com; Secure; SameSite=Strict`
    const tokenV2 = await getToken(value)
    const signedToken = tokenV2?.tokenSigned
    document.cookie = `userToken=${signedToken}; expires=${expirationTime}; path=/; domain=.kolo-app.com; Secure; SameSite=Strict`
    return
  }

  document.cookie = `${name}=${value}; expires=${expirationTime}; path=/; domain=.kolo-app.com; Secure; SameSite=Strict`
}

export { setCookie }
