enum CookieEnum {
  userToken = 'userToken',
  company = 'company',
  authToken = 'authToken',
  currentUser = 'currentUser',
}

const CookieExpirationHours = {
  [CookieEnum.userToken]: 1,
  [CookieEnum.company]: 24,
  [CookieEnum.authToken]: 1,
  [CookieEnum.currentUser]: 24,
}

export { CookieEnum, CookieExpirationHours }
